import React, { PropsWithChildren } from 'react'
import * as styles from './Skeleton.module.scss'

interface SkeletonProps {
    rows : number,
    styles ? : Styles
}

interface Styles {
    width ?: string,
    height ?: string,
    margin ? : string | number, // you can put only 0
    align ? : string 
}

function SkeletonBlock(props: PropsWithChildren<SkeletonProps>) {
    
    const {rows} = props
    
    return (
        <>
        { rows !== 1 &&
        <div className={styles.skeletonWrapper} style={{
            ["--skeletonAlign" as string]: props.styles?.align && `${props.styles?.align}`
        }}>
            {[...Array(rows)].map((empy, i)=> <div key={'skeleton'+i} className={styles.skeletonBlock} style={{
                ["--skeletonWidth" as string]: props.styles?.width && `${props.styles?.width}`,
                ["--skeletonHeight" as string]: props.styles?.height && `${props.styles?.height}`,
                ["--skeletonMargin" as string]: props.styles?.margin && `${props.styles?.margin}`
            }}></div>)}
        </div>
        }
        {
            rows === 1 && (
                <div className={styles.skeletonBlock} style={{
                    ["--skeletonWidth" as string]: props.styles?.width && `${props.styles?.width}`,
                    ["--skeletonHeight" as string]: props.styles?.height && `${props.styles?.height}`,
                    ["--skeletonMargin" as string]: props.styles?.margin && `${props.styles?.margin}`,
                    ["--skeletonAlign" as string]: props.styles?.align && `${props.styles?.align}`
                }}></div>
            )
        }
        </>
    )
}

export default SkeletonBlock
