// #region Imports
import React, { PropsWithChildren, ReactElement, useLayoutEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { IChildrenMenuItem } from "../Interfaces/MenuItemsInterfaces";
import { useEventListener } from "../../../../hooks/UseEventListener";
import * as styles from './ChildrenDropdown.module.scss'
import { useLocation } from '@reach/router';
// #endregion

// #region Interfaces
interface ChildrenDropdownProps {
    items: IChildrenMenuItem[];
    handleLinkClick?: () => void;
}
// #endregion

const ChildrenDropdown = (props: PropsWithChildren<ChildrenDropdownProps>): ReactElement => {
    // #region Component properties
    const dropdown = useRef(null as any);
    const [isOverflowing, setIsOverflowing] = useState(false);
    // #endregion

    const checkOverflow = (): void => {
        const { scrollWidth, clientWidth } = dropdown.current;
        setIsOverflowing(clientWidth < scrollWidth);
    }
    // #endregion

    // #region Effects
    useLayoutEffect(checkOverflow, []);
    // #endregion

    // #region Hooks
    useEventListener("resize", checkOverflow);
    // #endregion

    // #region Current location
    const location = useLocation();
    // #endregion Current location

    // #region JSX
    return (
        <ul ref={dropdown} className={styles.childrenDropdown}>
            {props.items.map((option: IChildrenMenuItem, i: number) => {
                return (
                    <li key={i}>
                        <Link
                            to={option.slug}
                            onClick={props.handleLinkClick}
                            className={`${styles.link} ${location.pathname === option.slug ? styles.current : ""}`}>
                            {option.name}
                        </Link>
                    </li>
                )
            })}
        </ul >
    );
    // #endregion
}

export default ChildrenDropdown;