// extracted by mini-css-extract-plugin
export var animateActiveLink = "Header-module--animate-active-link--913ca";
export var childrenSocials = "Header-module--childrenSocials--053cd";
export var ctaSection = "Header-module--ctaSection--58ba4";
export var fadeInLeft = "Header-module--fade-in-left--31397";
export var fadeInRight = "Header-module--fade-in-right--49c52";
export var fadeInUp = "Header-module--fade-in-up--6c15b";
export var fadeOutDown = "Header-module--fade-out-down--99b03";
export var fadeOutLeft = "Header-module--fade-out-left--f9b8c";
export var gradient = "Header-module--gradient--20bfa";
export var header = "Header-module--header--1b4d8";
export var headerJoinNow = "Header-module--headerJoinNow--76eb7";
export var headerLogo = "Header-module--headerLogo--f8c53";
export var isActive = "Header-module--isActive--5f8b7";
export var logAndRegister = "Header-module--logAndRegister--9eb9d";
export var logged = "Header-module--logged--5f6bb";
export var mainNavigation = "Header-module--mainNavigation--dfd02";
export var reveal = "Header-module--reveal--96a4f";
export var reveal3d = "Header-module--reveal3d--e7006";
export var socialMediaWrapper = "Header-module--socialMediaWrapper--1b2e0";