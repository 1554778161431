// extracted by mini-css-extract-plugin
export var arrowBtn = "MenuNav-module--arrowBtn--d00a7";
export var closeBtn = "MenuNav-module--closeBtn--b27ba";
export var container = "MenuNav-module--container--8cc96";
export var dashboardBtn = "MenuNav-module--dashboardBtn--cce7e";
export var hasOptions = "MenuNav-module--hasOptions--9a87b";
export var isMobile = "MenuNav-module--isMobile--f5b76";
export var menuNav = "MenuNav-module--menuNav--aa11c";
export var navItem = "MenuNav-module--navItem--1ec79";
export var open = "MenuNav-module--open--eb8d8";
export var reveal3d = "MenuNav-module--reveal3d--a5e13";