import { useStaticQuery, graphql } from "gatsby";

export default () => {

   const data = useStaticQuery(
    graphql`
           query NavDataQuery{
                allPrismicNavigation {
                edges {
                    node {
                        dataRaw
                    }
                }
            }
        }
        `
  );
  return data.allPrismicNavigation?.edges[0].node.dataRaw.nav ?? {};

};
