// extracted by mini-css-extract-plugin
export var fadeInLeft = "Skeleton-module--fade-in-left--4932e";
export var fadeInRight = "Skeleton-module--fade-in-right--c6d1f";
export var fadeInUp = "Skeleton-module--fade-in-up--16777";
export var fadeOutDown = "Skeleton-module--fade-out-down--b6cb2";
export var fadeOutLeft = "Skeleton-module--fade-out-left--417ad";
export var gradient = "Skeleton-module--gradient--c1400";
export var reveal = "Skeleton-module--reveal--d8905";
export var reveal3d = "Skeleton-module--reveal3d--fd0a3";
export var skeletonBlock = "Skeleton-module--skeletonBlock--64f70";
export var skeletonWrapper = "Skeleton-module--skeletonWrapper--c6dea";