import React, { PropsWithChildren, ReactElement } from "react";
import NavItem from "../NavElements/NavItem";
import * as styles from "./NavbarDesktop.module.scss";

interface NavbarDesktopProps
{
    navData: any;
}

const NavbarDesktop = ({ navData }: PropsWithChildren<NavbarDesktopProps>): ReactElement =>{ 

    return <nav className={styles.navbarDesktop}>
        <ul className={styles.navItemsWrapper}>
            {navData.map((item: object, i: number) => (
                <NavItem key={`${i} option`} {...{ item }}/>
            ))}
        </ul>
    </nav>
}
export default NavbarDesktop;
