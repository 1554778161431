// #region Imports
import React, {
    useState,
    ReactElement,
    PropsWithChildren,
    useRef,
} from "react";
import { Link } from "gatsby";
import { chevron } from "../../../../../shared/icons";
import NavDropdown from "./NavDropdown";
import { routes } from "../../../../../routes";
import { isBrowser } from "../../../../../shared/helpers";
import { useEventListener } from "../../../../../hooks/UseEventListener";

// #endregion

// #region Interfaces
export interface Item {
    name: string;
    slug: string;
    items?: Item[];
    children?: Item[];
}

interface NavItemProps {
    item: Item;
    handleLinkClick?: () => void;
}
// #endregion

const isIOS = isBrowser() ? /iPhone|iPad|iPod/.test( navigator.userAgent ) : false

const NavItem = (props: PropsWithChildren<NavItemProps>): ReactElement => {
    // #region Component properties
    const [optionsVisible, setOptionsVisibility] = useState(false);
    const itemRef = useRef(null as any);
    // #endregion

    const hasChildren = props.item.slice_type === "nav_item_multiple";

    function handleClickOutside(e: Event) {
        if (
            itemRef?.current &&
            !itemRef.current.contains(e.target as HTMLElement)
        )
            setOptionsVisibility(false);
    }
    useEventListener("click", handleClickOutside);
    const pointerEventName = isIOS ? "onMouseDown" : "onPointerDown";

    // #region JSX
    return (
        <li
            ref={itemRef}
            className={`navItem ${hasChildren ? "hasOptions" : ""}`}
        >
            {hasChildren ? (
                <div>
                    <button
                        className={
                            optionsVisible ? "optionsVisible" : ""
                        }
                        onClick={() => {
                            setOptionsVisibility(
                                prevState => !prevState,
                            );
                        }}
                    >
                        {props.item.primary.nav_item_label[0].text}
                        {<span>{chevron.down}</span>}
                    </button>
                </div>
            ) : (
                <div>
                    <Link
                        to={`${routes[props.item.primary.nav_item_link.type]}`}
                        {...{
                            [pointerEventName]: props.handleLinkClick,
                        }}
                    >
                        {props.item.primary.nav_item_label[0].text}
                    </Link>
                </div>
            )}

            {hasChildren && optionsVisible && (
                <NavDropdown
                    items={props.item.items}
                    handleLinkClick={props.handleLinkClick}
                />
            )}
        </li>
    );
    // #endregion
};

export default NavItem;
