import * as Prismic from "@prismicio/client";

const API_URL = `https://${process.env.GATSBY_REPO_NAME}.cdn.prismic.io/api/v2`;
const API_TOKEN = process.env.GATSBY_REPO_TOKEN;

class PrismicService {
    private static instance: PrismicService;
    private api: any;

    private constructor() {};

    public static async getInstance(): Promise<PrismicService> {
        
        if (!PrismicService.instance) {
            PrismicService.instance = new PrismicService();            
            await PrismicService.instance.initialize();
        }

        return Promise.resolve(PrismicService.instance);
    }

    public async getDocuments(type: string) {
        if (PrismicService.instance.api)
        {
            return await PrismicService.instance.api.get({predicates:Prismic.predicate.at("document.type", type)});
        } else {
            return await PrismicService.instance.initialize().then( 
                async () =>  await PrismicService.instance.api.query(Prismic.predicate.at("document.type", type))
            )
        }
    };

    public async getDocumentsFields(type: string, fields: string[]) {
        if (PrismicService.instance.api)
        {
            return await PrismicService.instance.api.get({predicates: Prismic.predicate.at("document.type", type), fetch : fields});
        } else {
            return await PrismicService.instance.initialize().then( 
                async () =>  await PrismicService.instance.api.query(Prismic.predicate.at("document.type", type), {fetch: fields})
            )
        }
    };

    public async getDocumentById(id: string) {
        if (PrismicService.instance.api)
        {
            return await PrismicService.instance.api.get({predicates:Prismic.predicate.at("document.d", id)});
        } else {
            return await PrismicService.instance.initialize().then( 
                async () =>  await PrismicService.instance.api.query(Prismic.predicate.at("document.id", id))
            )
        }
    };

    public async getDocumentsByIds(ids: string[]) {
        if (PrismicService.instance.api)
        {
            return await PrismicService.instance.api.get({predicates:Prismic.predicate.in("document.id", ids)});
        } else {
            return await PrismicService.instance.initialize().then( 
                async () =>  await PrismicService.instance.api.query(Prismic.predicate.in("document.id", ids))
            )
        }
    };

    public async initialize() {
        const client = Prismic.createClient(API_URL, { accessToken: API_TOKEN })
        this.api = client
        return client;
    }
}

export default PrismicService;
