// extracted by mini-css-extract-plugin
export var Link = "Footer-module--Link--417ef";
export var firstSection = "Footer-module--firstSection--e0676";
export var footer = "Footer-module--footer--bfdf8";
export var footerLogo = "Footer-module--footerLogo--c2673";
export var footerLogoWrapper = "Footer-module--footerLogoWrapper--00b46";
export var lastSection = "Footer-module--lastSection--3f7c7";
export var leftControlGroup = "Footer-module--leftControlGroup--5b40b";
export var navItemCategory = "Footer-module--navItemCategory--4667d";
export var rightControlGroup = "Footer-module--rightControlGroup--70153";
export var socialMediaWrapper = "Footer-module--socialMediaWrapper--37590";