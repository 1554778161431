// extracted by mini-css-extract-plugin
export var active = "Layout-module--active--048d6";
export var childrensWrapper = "Layout-module--childrensWrapper--da756";
export var freeze = "Layout-module--freeze--0ca2a";
export var ico = "Layout-module--ico--d0e7e";
export var imgBackground = "Layout-module--imgBackground--b6899";
export var inactive = "Layout-module--inactive--1c701";
export var info = "Layout-module--info--8b2cc";
export var mainMemberContent = "Layout-module--mainMemberContent--77c4b";
export var status = "Layout-module--status--4b6d5";
export var statusWrapper = "Layout-module--statusWrapper--6b5ba";
export var tooltip = "Layout-module--tooltip--6e693";
export var tooltipItem = "Layout-module--tooltipItem--b6f1c";