exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-contact-us-thank-you-tsx": () => import("./../../../src/pages/contact-us/thank-you.tsx" /* webpackChunkName: "component---src-pages-contact-us-thank-you-tsx" */),
  "component---src-pages-corporate-wellness-index-tsx": () => import("./../../../src/pages/corporate-wellness/index.tsx" /* webpackChunkName: "component---src-pages-corporate-wellness-index-tsx" */),
  "component---src-pages-corporate-wellness-thank-you-tsx": () => import("./../../../src/pages/corporate-wellness/thank-you.tsx" /* webpackChunkName: "component---src-pages-corporate-wellness-thank-you-tsx" */),
  "component---src-pages-gym-members-policy-index-tsx": () => import("./../../../src/pages/gym-members-policy/index.tsx" /* webpackChunkName: "component---src-pages-gym-members-policy-index-tsx" */),
  "component---src-pages-members-tsx": () => import("./../../../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-scheduler-tsx": () => import("./../../../src/pages/scheduler.tsx" /* webpackChunkName: "component---src-pages-scheduler-tsx" */),
  "component---src-pages-site-map-index-tsx": () => import("./../../../src/pages/site-map/index.tsx" /* webpackChunkName: "component---src-pages-site-map-index-tsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */),
  "component---src-pages-try-us-index-tsx": () => import("./../../../src/pages/try-us/index.tsx" /* webpackChunkName: "component---src-pages-try-us-index-tsx" */),
  "component---src-pages-try-us-thank-you-tsx": () => import("./../../../src/pages/try-us/thank-you.tsx" /* webpackChunkName: "component---src-pages-try-us-thank-you-tsx" */),
  "component---src-templates-classes-archive-index-tsx": () => import("./../../../src/templates/Classes_archive/index.tsx" /* webpackChunkName: "component---src-templates-classes-archive-index-tsx" */),
  "component---src-templates-classes-index-tsx": () => import("./../../../src/templates/Classes/index.tsx" /* webpackChunkName: "component---src-templates-classes-index-tsx" */),
  "component---src-templates-content-page-index-tsx": () => import("./../../../src/templates/Content_page/index.tsx" /* webpackChunkName: "component---src-templates-content-page-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/Home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-join-us-page-index-tsx": () => import("./../../../src/templates/JoinUs_page/index.tsx" /* webpackChunkName: "component---src-templates-join-us-page-index-tsx" */),
  "component---src-templates-kids-program-index-tsx": () => import("./../../../src/templates/Kids_program/index.tsx" /* webpackChunkName: "component---src-templates-kids-program-index-tsx" */),
  "component---src-templates-locations-index-tsx": () => import("./../../../src/templates/Locations/index.tsx" /* webpackChunkName: "component---src-templates-locations-index-tsx" */),
  "component---src-templates-thank-you-page-index-tsx": () => import("./../../../src/templates/ThankYou_page/index.tsx" /* webpackChunkName: "component---src-templates-thank-you-page-index-tsx" */),
  "component---src-templates-trainers-page-index-tsx": () => import("./../../../src/templates/Trainers_page/index.tsx" /* webpackChunkName: "component---src-templates-trainers-page-index-tsx" */)
}

