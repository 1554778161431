import React, { PropsWithChildren, ReactElement } from "react";
import { Item } from "./NavElements/NavItem";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import NavbarDesktop from "./NavbarDesktop";
import NavbarMobile, { IMobileButtonData } from "./NavbarMobile";


interface NavbarProps {
    navData: Item[];
    mobileButtonData: IMobileButtonData;
}

export const navDesktopMinWidth = 1200;


const Navbar = (props: PropsWithChildren<NavbarProps>): ReactElement => {
    const {navData, mobileButtonData} = props
    const isDesktop = useWindowWidth(navDesktopMinWidth);
    return isDesktop ? (
        <NavbarDesktop {...{ navData }} />
    ) : (
        <NavbarMobile {...{ navData }} btnData={mobileButtonData}>
        {props.children}
        </NavbarMobile>
    );
};

export default Navbar;
