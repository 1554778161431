import React, {
    ReactElement,
    useRef,
    PropsWithChildren,
} from "react";
import { Link, graphql, useStaticQuery, navigate } from "gatsby";
import Navbar, { navDesktopMinWidth } from "./Navbar";
import * as styles from "./Header.module.scss";
import { routes } from "../../../routes";
import { socialMediaIcons } from "../../../shared/icons";
import { headerConfig } from "../layoutConfig";
import { UserDataContext } from "../../../contexts/DependencyContext";
import { isWindow } from "../../../shared/helpers";
import LoggedMenu from "./Navbar/LoggedMenu";
import useWindowWidth from "../../../hooks/useWindowWidth";
import useHasWindow from "../../../hooks/useHasWindow";

const Header = (props: PropsWithChildren<any>): ReactElement => {
    const hasWindow = useHasWindow();

    const header = useRef<any>();
    const query = useStaticQuery(graphql`
        query {
            allPrismicMainLayout {
                edges {
                    node {
                        dataRaw
                    }
                }
            }
        }
    `);

    const isDesktop = useWindowWidth(navDesktopMinWidth);
    const data = query.allPrismicMainLayout.edges[0].node.dataRaw;
    const { navData } = props;
    const mobileButtonData = {
        tryUs: {
            text: data.header_try_us_button_text,
            link: data.header_try_us_link,
        },
        contactUs: {
            text: data.header_contact_us_button_text,
            link: data.header_contact_us_link,
        },
    };

    const paths = Object.values(headerConfig.buttonsExcludedPath);

    const logOut = (e: any) => {
        e.preventDefault();
        localStorage.removeItem("jwt-token");
        navigate("/members/login");
    };

    return (
        <header
            ref={header}
            className={`${styles.header} print-hidden`}
        >
            <div className={styles.socialMediaWrapper}>
                {hasWindow && !localStorage.getItem("jwt-token") && (
                    <Link
                        to={"/members/login"}
                        className={styles.logAndRegister}
                    >
                        Log In / Register
                    </Link>
                )}
                {data.footer_facebook_url && (
                    <a
                        target="_blank"
                        href={data.footer_facebook_url.url}
                        rel="noreferrer noopener"
                    >
                        {socialMediaIcons.facebook}
                    </a>
                )}

                {data.footer_instagram_url && (
                    <a
                        target="_blank"
                        href={data.footer_instagram_url.url}
                        rel="noreferrer noopener"
                    >
                        {socialMediaIcons.instagram}
                    </a>
                )}
            </div>
            <div className={styles.mainNavigation}>
                <Link to="/" className={styles.headerLogo}>
                    <img
                        src={data.header_site_logo.url}
                        alt={data.header_site_logo.alt}
                    />
                </Link>
                <Navbar
                    navData={navData}
                    mobileButtonData={mobileButtonData}
                >
                    <>
                        {/* <div className={`${styles.childrenSocials} ${(hasWindow && localStorage.getItem('jwt-token')) ? styles.logged : ""}`}>
                    {data.footer_facebook_url && (
                        <a
                            target="_blank"
                            href={data.footer_facebook_url.url}
                            rel="noreferrer noopener"
                        >
                            {socialMediaIcons.facebook}
                        </a>
                    )}

                    {data.footer_instagram_url && (
                        <a
                            target="_blank"
                            href={data.footer_instagram_url.url}
                            rel="noreferrer noopener"
                        >
                            {socialMediaIcons.instagram}
                        </a>
                    )}
                </div> */}
                    </>
                </Navbar>
                {!paths.includes(props.location.path) &&
                    hasWindow &&
                    !localStorage.getItem("jwt-token") && (
                        <div className={styles.ctaSection}>
                            {data.header_contact_us_button_text &&
                                data.header_contact_us_link && (
                                    <Link
                                        to={`${routes[data.header_contact_us_link?.type]}`}
                                        className={
                                            styles.headerJoinNow
                                        }
                                        state={{ club: null }}
                                    >
                                        {
                                            data
                                                .header_contact_us_button_text[0]
                                                ?.text
                                        }
                                    </Link>
                                )}
                            {data.header_try_us_button_text &&
                                data.header_try_us_link && (
                                    <Link
                                        to={`${routes[data.header_try_us_link?.type]}`}
                                        className={
                                            styles.headerJoinNow
                                        }
                                        state={{ club: null }}
                                    >
                                        {
                                            data
                                                .header_try_us_button_text[0]
                                                ?.text
                                        }
                                    </Link>
                                )}
                        </div>
                    )}
                {hasWindow &&
                    localStorage.getItem("jwt-token") &&
                    isDesktop && <LoggedMenu />}
            </div>
        </header>
    );
};

export default Header;
