// #region Imports
import React, {
    PropsWithChildren,
    ReactElement,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { Link } from "gatsby";
import { useEventListener } from "../../../../../hooks/UseEventListener";
import { Item } from "./NavItem";
import { routes } from "../../../../../routes";
import * as styles from "./NavItem.module.scss";

// #endregion

// #region Interfaces
interface NavDropdownProps {
    items: Item[];
}
// #endregion

const NavDropdown = (
    props: PropsWithChildren<NavDropdownProps>,
): ReactElement => {
    // #region Component properties
    const dropdown = useRef(null as any);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    // #endregion

    const checkOverflow = (): void => {
        const { scrollWidth, clientWidth } = dropdown.current;
        setIsOverflowing(clientWidth < scrollWidth);
    };
    // #endregion

    // #region Effects
    useLayoutEffect(checkOverflow, []);
    // #endregion

    // #region Hooks
    useEventListener("resize", checkOverflow);
    // #endregion

    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };

    if (!isMenuOpen) return <></>;

    // #region JSX
    return (
        <ul ref={dropdown} className={`navDropdown ${isOverflowing ? "withOverflow" : ""} ${styles.animate}` }>
            {props.items.map((option: object, i: number) => {
                const { uid, type } = option.nav_link;
                const linkPath = uid ? `/${type}/${uid}` : routes[type];
                return (
                    <li key={i}>
                        <Link to={linkPath} onClick={handleLinkClick} >
                            {option.nav_link_label[0].text}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
    // #endregion
};

export default NavDropdown;
